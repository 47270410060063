import React, { Component } from "react"
import { Link } from "gatsby"
import { Location } from "@reach/router"
import { withTranslation } from "react-i18next"

import HeaderLogo from "../images/logo-header.svg"
import ARLogo from "../images/ar-02.svg"
import ENLogo from "../images/en-02.svg"

class Header extends Component {
  constructor(props) {
    super(props)

    this.toggleActive = this.toggleActive.bind(this)

    this.state = {
      active: false,
    }
  }

  toggleActive() {
    const { active } = this.state

    this.setState({
      active: !active,
    })
  }

  render() {
    const { active } = this.state
    const { t, i18n } = this.props
    const { language } = i18n
    const linkPrefix = language === "en" ? "/en" : ""

    return (
      <Location>
        {({ location: { pathname } }) => {
          return (
            <header className="main-header">
              <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <Link
                  className="navbar-brand ml-4"
                  to={i18n.language === "en" ? "/en" : "/"}
                >
                  <img
                    height="50"
                    src={HeaderLogo}
                    className="mb-0 d-block"
                    alt=""
                  />
                </Link>
                <button
                  onClick={this.toggleActive}
                  className="navbar-toggler"
                  type="button"
                >
                  <span className="navbar-toggler-icon" />
                </button>

                <div
                  className={`collapse navbar-collapse justify-content-between ${
                    active ? "show" : ""
                  }`}
                >
                  <ul className="navbar-nav align-items-center">
                    <li
                      className={`nav-item mx-2 ${
                        pathname === "/about-us" ? "active" : ""
                      }`}
                    >
                      <Link className="nav-link" to={`${linkPrefix}/about-us`}>
                        {t("about")}
                      </Link>
                    </li>
                    <li
                      className={`nav-item mx-2 ${
                        pathname === "/maps" ? "active" : ""
                      }`}
                    >
                      <Link className="nav-link" to={`${linkPrefix}/maps`}>
                        {t("maps")}
                      </Link>
                    </li>
                    <li
                      className={`nav-item mx-2 ${
                        pathname === "/articles" ? "active" : ""
                      }`}
                    >
                      <Link className="nav-link" to={`${linkPrefix}/articles`}>
                        {t("articles")}
                      </Link>
                    </li>
                    <li
                      className={`nav-item mx-2 ${
                        pathname === "/multimedia" ? "active" : ""
                      }`}
                    >
                      <Link
                        className="nav-link"
                        to={`${linkPrefix}/multimedia`}
                      >
                        {t("multimedia")}
                      </Link>
                    </li>
                    <li
                      className={`nav-item mx-2 ${
                        pathname === "/contact-us" ? "active" : ""
                      }`}
                    >
                      <Link
                        className="nav-link"
                        to={`${linkPrefix}/contact-us`}
                      >
                        {t("contact")}
                      </Link>
                    </li>
                    <li className={`nav-item mx-2`}>
                      <Link
                        className="nav-link"
                        to={`${language === "ar" ? "/en" : "/"}`}
                      >
                        {language === "ar" ? (
                          <span className="fa icon-en-02 fa-xs font-weight-normal"></span>
                        ) : (
                          <div className="d-flex justify-content-center align-items-center">
                            <span className="fa icon-ar-02 fa-2x font-weight-normal"></span>
                          </div>
                        )}
                      </Link>
                    </li>
                  </ul>
                  <ul className="navbar-nav flex-row justify-content-center ml-lg-1 mt-4 mt-lg-0">
                    <li className="nav-item mx-1">
                      <a
                        className="nav-link"
                        href="https://www.instagram.com/almadaq.cairo/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li className="nav-item mx-1">
                      <a
                        className="nav-link"
                        href="https://vimeo.com/user107974996"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-vimeo-square" />
                      </a>
                    </li>
                    <li className="nav-item mx-1">
                      <a
                        className="nav-link"
                        href="https://www.facebook.com/almadaq.cairo"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-square" />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
          )
        }}
      </Location>
    )
  }
}

export default withTranslation()(Header)
