import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import cleanUpQuotes from "../utils/cleanup-quotes"
import gatsbyi18Context from "../contexts/gatsby-i18n-context.js"

export default ({ pageContext }) => {
  const { title, content, langKey: language } = pageContext
  const { i18n } = useTranslation()

  const data = {
    language,
  }

  return (
    <gatsbyi18Context.Provider value={data}>
      <Layout className="has-header">
        <SEO title={title} />
        <Header />
        <div className="with-bg h-100 minimum-full-height">
          <div className="container">
            <article className="py-4">
              <div className="decorator my-5" />
              <header>
                <h1>{title}</h1>
              </header>
              <section
                dangerouslySetInnerHTML={{ __html: cleanUpQuotes(content) }}
              />
              <div className="decorator my-5 mr-auto" />
            </article>
          </div>
        </div>
        <Footer />
      </Layout>
    </gatsbyi18Context.Provider>
  )
}
