import React from 'react'
import {Link, StaticQuery, graphql} from 'gatsby'
import { useTranslation } from 'react-i18next'

export default (data) => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  return (
    <footer className="main">
      <div className="container">
        <div className="inner d-flex mb-3">
          <span>
            <small>
              {t('almadaq')}
            </small>
          </span>
          <Link className="mr-auto" to={`${language === 'en' ? '/en': ''}/privacy-policy`}>
            <small>
              {t('privacy policy')}
            </small>
          </Link>
        </div>
      </div>
    </footer>
  )
}
